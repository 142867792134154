import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Animate from "../components/animate"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../styles/_pageContact.module.scss"

const ContactPage = () => {

  return (
    <Layout home={false}>
      <header className="content-header">
        <Animate className="fadeIn" triggerOnce={true}>
          <h1 className="title1" style={{ transitionDelay: `1s` }}><span>お問い合わせ</span>Contact</h1>
        </Animate>
      </header>
      <div className={styles.wrapper}>
        <div className={styles.decoration}>
          <StaticImage
            src="../images/bg3.jpg"
            loading="lazy"
            alt="Abema Towers外観"
          />
        </div>
        <div className={styles.form}>
          <Animate className="fadeIn" triggerOnce={true}>
            <iframe className={styles.iframe} title="お問い合わせフォーム" width="100%" height="1240" src="https://docs.google.com/forms/d/e/1FAIpQLSdl66vT1o0xMpa31e63nYWNQybGCpSUDxaun6FJ_FCppDwOtA/viewform?embedded=true">読み込んでいます…</iframe>
          </Animate>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title="お問い合わせ" pathname="/contact/" />

export default ContactPage
